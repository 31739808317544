@value vRadiusMiddle, vRed6, vGray16 from "../../../../styles/variables.css";

.searchBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: vRadiusMiddle;
    z-index: 10;
}

.searchInput {
    font-size: 1.25rem;
}

.searchInput::-webkit-input-placeholder {
    color: vGray16;
}
.searchInput::-moz-placeholder {
    color: vGray16;
}
.searchInput:-ms-input-placeholder {
    color: vGray16;
}

.actions {
    display: flex;
    align-items: center;
    padding: 0 0 0 16px;
}

.action {

}

.action + .action {
    margin-left: 18px;
}

.iconSearch svg path {
    fill: #000;
}

.iconFilter {
    position: relative;
}

/*.iconFilter_active:after {
    position: absolute;
    top: 1px;
    right: -2px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: vRed6;
    content: '';
}*/
