.wrap {
    padding: 0 0 20px;
}

.fieldText {
    padding: 0 10px 0 0;
}

.link {
    color: #000;
}
