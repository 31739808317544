@value vRadiusMiddle from "../../../styles/variables.css";

.image {
    background-color: #fff;
    border-radius: vRadiusMiddle vRadiusMiddle 0 0;
    outline: none;
    overflow: hidden;
}

.image_link {
    cursor: pointer;
    text-decoration: none;
}
