@value vGreen8, vBlue11, vGray14 from "../../styles/variables.css";

.field {

}

.fieldText {
    font-size: .875rem;
}

.fieldValue {
    margin-top: 5px;
    font-weight: 500;
}

.fieldSelect {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
}

.link {
    color: vBlue11;
    text-decoration: none;
}
