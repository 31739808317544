@value vGutterX, vPrimary3, vViolet1, vGray4 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.goods {
    margin-top: calc(vGutterX / 2);
}

.head {
    position: relative;
    padding: 24px 60px 0 12px;
}

.name {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
}

.headControls {
    position: absolute;
    top: 20px;
    right: 25px;
}

.imageWrap {
    padding: 20px 0 0;
}

.body {
    padding: 20px 12px 30px;
}

.subTitle {
    padding: 32px 0 16px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
}

.prices {
    padding: 20px 0 0;
}

.pricesItem {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.pricesItem + .pricesItem {
    margin-top: 16px;
}

.price {
    font-size: 1.875rem;
    line-height: 1;
    white-space: nowrap;
}

.price_kicks {
    color: vPrimary3;
}

.price_cv {
    color: vViolet1;
}

.priceOld {
    position: relative;
    margin-left: 20px;
    font-size: 1.75rem;
    line-height: 1;
    color: vGray4;
    white-space: nowrap;
}

.priceOld:after {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: vGray4;
    content: '';
}

.controls {
    padding: 32px 0 8px;
}

.iconPrice {
    display: inline-block;
    vertical-align: top;
}

.iconPrice,
.iconPrice svg {
    width: 24px;
    height: 28px;
}

.iconPrice svg path {
    fill: vPrimary3;
}

.desc {

}

.descText {
    max-height: 90px;
    line-height: 1.125;
    transition: max-height .3s ease;
    overflow: hidden;
}

.descText_show {
    max-height: 1000px;
}

.addToCartControl {
    font-size: 1.25rem;
}

.descControl {
    color: vPrimary3;
    text-decoration: underline;
}

.descControl:hover {
    text-decoration: none;
}

.buttonShare {
    position: relative;
    composes: clickable from "../../../../styles/modules/common.module.css";
}

.iconShare {
    width: 30px;
    height: 30px;
}

@media tablet-landscape-up {
    .head {
        padding: 24px 60px 0 24px;
    }

    .body {
        padding: 20px 24px 30px;
    }
}
