@value vPrimary1 from "../../styles/variables.css";

.field {

}

.field_textTransparent {
    color: transparent;
}

.field_unclickable {
    pointer-events: none;
}

.fieldText_clickable {
    cursor: pointer;
}

.fieldLabel {
    cursor: pointer;
}

.fieldArrow {
    composes: fieldArrow from "../../components/CustomSelect/CustomSelectField/CustomSelectField.module.css";
    position: absolute;
    top: 0;
    right: 14px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.selectField {
    composes: dynamic from "../../components/Input/Input.module.css";
    position: relative;
}

.selectField_placeholder {
    composes: placeholder from "../../components/Input/Input.module.css";
}

.selectField_placeholderTop {
    top: 1px;
    padding-top: 2px;
    font-size: .75rem;
    transform: translateY(0);
}

.selectField_value {
    padding: 16px 0 0;
    font-size: 1rem;
}

.action {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.actionDelete {
    width: auto;
    margin-left: auto;
}

.actionDeleteText {
    padding-right: 6px;
}

.modalBody {
    padding-top: 20px;
}

.modalAction {
    width: 156px;
    margin-left: auto;
    margin-right: auto;
}

.iconAddPhoto {
    composes: cross from "../../styles/modules/common.module.css";
    width: 20px;
    height: 20px;
    margin-left: 9px;
}

.iconAddPhoto:before,
.iconAddPhoto:after {
    background-color: vPrimary1;
}

.iconAddPhoto:before {
    width: 20px;
    height: 2px;
}

.iconAddPhoto:after {
    width: 2px;
    height: 20px;
}

.iconDelete svg path {
    stroke: #000;
}
