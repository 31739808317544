@value vWhite1 from "../../styles/variables.css";

.fieldTextarea {
    background-color: vWhite1;
}

.controls {
    padding: 15px 0 10px;
}

.control {

}
