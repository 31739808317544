@value vGutterX, vRadius, vGray10, vGray2, vGray14 from "../../../styles/variables.css";
@value desktop-up from "../../../styles/breakpoints.css";

.innerWrap {

}

.contentInner {
    width: 100%;
}

.sidebarInner {
    width: 100%;
    padding-top: calc(vGutterX / 2);
}

.block {
    background-color: #fff;
}

.block_form {

}

.block_reports {
    margin-top: 20px;
}

.blockHeader {
    padding: 20px 15px;
}

.blockHeader_row {
    display: flex;
    align-items: center;
}

.blockHeader_bordered {
    border-bottom: 1px solid vGray10;
}

.blockHeader_gray {
    background-color: vGray2;
}

.blockHeader_gap {
    margin: 10px;
    padding: 20px 15px;
    border-radius: vRadius;
}

.blockTitle {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1;
}

.blockTitle_flex {
    display: flex;
}

.blockTitleTooltip {
    margin-left: 4px;
    font-size: 0;
}

.blockCount {
    margin-left: 4px;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1;
    color: vGray14;
}

.blockBody {
    padding: 12px 15px 15px;
}

.reports {

}

.reportsHeader {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 8px;
}

.reportsCurrency {
    margin-top: 4px;
    font-size: .875rem;
    font-weight: 500;
    color: vGray14;
}

.reportsActions {
    display: flex;
}

.reportsAction {
    padding: 5px;
}

.reportsAction_active {
    background-color: #fff;
    border-radius: 6px;
    cursor: default;
}

.reportsAction + .reportsAction {
    margin-left: 5px;
}

.reportsActionSelectField {
    padding: 0 5px;
    background-color: #fff;
    border-radius: 6px;
}

.reportsTableTitle {
    display: none;
    padding: 5px 25px 0;
    font-size: 1.125rem;
    line-height: 1;
}

.reportsTableView {
    padding: 0 15px 15px;
}

.reportsChartView {
    padding: 15px 10px 30px;
}

.presets {
    padding: 10px 0 30px;
}

.tableCol {
    width: 33.33%;
    padding: 5px 5px 0 0;
}

.tableColTitle {
    margin-bottom: 5px;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1;
}

.tableColValue {
    font-size: .875rem;
}

.tableFooterTitle {
    font-size: .875rem;
    font-weight: 500;
    text-transform: uppercase;
}

.iconView svg * {
    fill: vGray14;
}

@media desktop-up {
    .innerWrap {
        display: flex;
    }

    .contentInner {
        width: 70%;
        padding-right: calc(vGutterX / 2);
    }

    .sidebarInner {
        width: 30%;
        padding-top: 44px;
        padding-left: calc(vGutterX / 2);
    }

    .blockHeader {
        padding: 25px;
    }

    .blockBody {
        padding: 12px 25px 30px;
    }

    .reportsTableView {
        padding: 15px 25px;
    }

    .reportsChartView {
        padding: 15px 25px 30px;
    }

    .reportsTableTitle {
        display: initial;
    }

    .tableColTitle {
        display: none;
    }
}
