@value vGutterX, vGutter, vGray10 from "../../../styles/variables.css";
@value tablet-up, desktop-up from "../../../styles/breakpoints.css";

.contentInner {
    position: relative;
    margin-top: calc(vGutterX / 2);
    flex-grow: 1;
}

.block {
    position: relative;
    padding: 24px 20px;
}

.blockTitle {
    font-size: 1.125rem;
    line-height: 1;
}

.filter {
    margin-top: 20px;
}

.filter_updating {
    composes: updating from "../../../styles/modules/common.module.css";
}

.list {
    padding: 44px 0 0;
}

.listItem {
    max-width: calc(50% - 8px);
    margin-right: 16px;
}

.transactions {
    position: relative;
    margin-top: 30px;
}

.mobileBar {
    padding-left: vGutter;
    padding-right: vGutter;
}

.payoutsMobile {
    display: flex;
    position: relative;
    padding-top: calc(vGutterX / 2);
    overflow-x: auto;
}

.modalHeader {
    border-bottom: 1px solid vGray10;
}

@media tablet-up {
    .block {
        padding: 24px 20px;
    }

    .mobileBar {
        padding-left: 0;
        padding-right: 0;
    }


}

@media desktop-up {
    .listItem {
        max-width: 100%;
        margin-right: 0;
    }

    .listItem + .listItem {
        margin-top: 16px;
    }
}
