@value vGutterX, vGray14 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";
@value vBlockGap: calc(vGutterX / 2);

.rowFiled {
    width: 100%;
}

.field {

}

.fieldSelect {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
}

.fieldIcon {
    margin: 0 calc(vGutterX / 2);
}

.control {
    margin: 0 auto;
    width: 50%;
}

.modalCalendarBody {
    min-height: 440px;
    padding: 12px 10px 20px;
}

.iconCalendar svg * {
    stroke: vGray14;
}

@media tablet-landscape-up {
    .rowFiled {
        flex-basis: calc((100% - vBlockGap) / 2);
        max-width: calc((100% - vBlockGap) / 2);
    }
}
