@value vLinkColor from "../../styles/variables.css";

.text {
    font-size: 14px;
    color: #5e616b;
    text-align: center;
}

.timer,
.action {
    padding: 20px 0 0;
    text-align: center;
}

.actionLink {
    font-size: 16px;
    color: vLinkColor;
}
