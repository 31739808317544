@value vHoverTransition, vGray2, vGray7, vGray8, vGray10, vGray12 from "../../../styles/variables.css";

.item {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color vHoverTransition;
}

.item_normal {
    padding: 6px 12px;
    border-radius: 4px;
    line-height: 1.2;
}

.item_normal:hover,
.item_active {
    background-color: vGray2;
}

.item_large {
    padding: 20px 30px 20px 0;
    font-size: .875rem;
    line-height: 1;
    white-space: nowrap;
    transition: color vHoverTransition;
}

.item_large + .item_large {
    border-top: 1px solid vGray10;
}

.item_large:hover {
    background-color: inherit;
    color: vGray7;
}

.item_hover:hover {
    background-color: vGray12;
}

.item_active,
.item_disabled {
    pointer-events: none;
    cursor: default;
}

.item_disabled {
    color: vGray8;
}
