@value vGutter, vGutterX from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.header {
    display: flex;
    align-items: center;
}

.buttonBack {
    display: block;
    width: auto;
    padding: 0 10px 0 calc(vGutter + 3px);
}

.title {

}

.title_left,
.title_center,
.title_right {
    flex-grow: 1;
}

.title_left {
    text-align: left;
}

.title_center {
    text-align: center;
}

.title_right {
    text-align: right;
}

.action {
    padding-top: 12px;
    padding-bottom: 12px;
}

.content {
    padding: 12px 0;
}

@media tablet-landscape-up {
    .action,
    .content {
        padding-top: 0;
        padding-bottom: 20px;
    }
}
