@value vGutter, vGutterX, vPageVerticalGapTop, vHoverTransition, vPrimary3, vGray1, vGray3, vGray14 from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up from "../../styles/breakpoints.css";

.pageContainer {
    padding: 0 vGutter;
}

.userInfoBlock {
    padding: 20px 15px 30px;
}

.menuNav {
    padding: 0 0 30px;
}

.menuNav_user {
    padding-top: 30px;
}

.menuStaticNav {

}

.menuStaticNav_user {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 15px 0;
    marker-start: none;
}

.menuStaticNav_business {
    list-style: none;
}

.menuStaticItem {

}

.menuStaticItem_user {
    padding-right: 4px;
    list-style: none;
}

.menuStaticItem_user + .menuStaticItem_user:before {
    padding-right: 4px;
    content: '•';
}

.menuStaticItem_business {
    line-height: 1.25;
}

.menuStaticLink {
    outline: none;
    text-decoration: none;
}

.menuStaticLink_user {
    line-height: 1.3;
    color: vGray1;
    transition: color vHoverTransition;
}

.menuStaticLink_user:hover {
    color: vPrimary3;
}

.menuStaticLink_business {
    color: vGray14;
}

.footerText {
    line-height: 1.3;
    color: vGray1;
}

@media tablet-up {
    .pageContainer {
        padding-left: 0;
        padding-right: 0;
    }
}

@media tablet-landscape-up {
    .pageContainer {
        padding-top: vPageVerticalGapTop;
    }
}
