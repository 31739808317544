@value vGray1, vGray14, vGray22, vBlue8 from "../../../../styles/variables.css";

.transaction {
    display: flex;
    padding: 0 0 12px;
    border-bottom: 1px solid vGray22;
}

.body {
    flex-grow: 1;
    padding: 12px 0 0 20px;
}

.link {
    display: block;
}

.avatar {
    height: 54px;
}

.title {
    font-size: .875rem;
    line-height: 1;
}

.order {
    margin-top: 8px;
}

.orderLink {
    font-size: .75rem;
    line-height: 1;
    color: vBlue8;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0 0 8px;
}

.amount {
    font-size: 1.125rem;
    line-height: 1;
    text-align: right;
    white-space: nowrap;
}

.status {
    margin-top: 4px;
    font-size: .75rem;
    line-height: 1;
    color: vGray14;
    text-align: right;
}
