@value vRadiusMiddle, vGray10 from "../../../styles/variables.css";
@value tablet-landscape-up from "../../../styles/breakpoints.css";

.content {

}

.sidebar {

}

.sidebarTitle {
    padding: 0 0 20px;
    font-size: 1.25rem;
    line-height: 1;
    font-weight: 500;
}

.block {

}

.block_sidebar {
    padding: 30px;
}

.formBlock {

}

.formHeader {
    padding: 15px 10px;
    background-color: #fff;
    border-radius: vRadiusMiddle vRadiusMiddle 0 0;
    border-bottom: 1px solid vGray10;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1;
}

.formBody {
    padding: 12px 10px 20px;
    background-color: #fff;
    border-radius: 0 0 vRadiusMiddle vRadiusMiddle;
}

.formFooter {

}

@media tablet-landscape-up {
    .block_sidebar {

    }

    .formHeader {
        padding: 25px;
    }

    .formBody {
        padding: 12px 25px 30px;
    }

    .formFooter {
        width: 76%;
        margin-left: auto;
        margin-right: auto;
    }
}
