@value vRadiusMiddle, vViolet8 from "../../../../styles/variables.css";
@value tablet-landscape-up, desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";

.item {
    position: relative;
    border-radius: vRadiusMiddle;
    flex-shrink: 0;
    cursor: pointer;
}

.background {
    border-radius: 12px;
    overflow: hidden;
}

.body {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 15px 15px;
    height: 100%;
}

.name {
    font-size: 1rem;
    line-height: 1;
    color: vViolet8;
    text-align: right;
    text-transform: uppercase;
    word-break: break-word;
    hyphens: auto;
}

.balance {
    margin-top: auto;
    display: flex;
}

.amount,
.currency {
    font-size: 1.5rem;
    line-height: 1;
    color: #fff;
}

.amount {
    font-weight: 500;
}

.desc {
    margin-top: 12px;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
}

@media tablet-landscape-up {
    .body {
        padding: 20px 20px 24px 30px;
    }

    .name {
        font-size: 2rem;
    }

    .amount,
    .currency {
        font-size: 2rem;
    }
}

@media desktop-up {
    .item {
        max-width: 100%;
    }
}

@media big-desktop-up {
    .body {
        padding: 30px 30px 48px 24px;
    }
}
