.rating {
    display: flex;
    align-items: center;
    height: 100%;
}

.icon  {
    width: 32px;
    height: 32px;
}

.text {
    padding: 0 0 0 10px;
    color: #fff;
    font-weight: 500;
}
