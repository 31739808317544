@value vPrimary1, vWhite1, vGray14 from "../../styles/variables.css";

.label {
    position: relative;
    cursor: pointer;
}

.label:active ~ .control {
    /*width: 26px;*/
}

.input {
    position: absolute;
    margin: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}

.switch {
    position: relative;
    display: block;
    width: 46px;
    height: 24px;
    background: vWhite1;
    border-radius: 12px;
    text-indent: -9999px;
}

.control {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: vGray14;
    border-radius: 12px;
    transition: .3s;
}

.control_active {
    left: 100%;
    background-color: vPrimary1;
    transform: translateX(-100%);
}
