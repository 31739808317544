.fieldSelect {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
    position: relative;
}

.controls {

}

.control {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
