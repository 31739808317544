.headerContent {
    overflow-y: hidden;
}

.formBody {
    composes: block from "../../../layout/Block/Block.module.css";
    padding: 20px 15px;
}

.formFooter {

}
