@value vGray16 from "../../styles/variables.css";

.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.date {
    font-size: .75rem;
    font-weight: 700;
    white-space: nowrap;
}

.date_capitalize {
    text-transform: capitalize;
}

.action {
    width: auto;
    padding: 10px;
}

.action_left {
    padding-left: 0;
}

.action_right {
    padding-right: 0;
}

.arrow {
    width: 8px;
    height: 8px;
    border-width: 0 1px 1px 0;
    border-color: vGray16;
}
