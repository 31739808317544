@value vBlue7 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.page {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    padding: 30px 0;
}

.page_user {
    background: linear-gradient(84deg, #62c5ff, #b84efa);
}

.page_business {
    background-color: vBlue7;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

@media tablet-up {
    .content {

    }
}
