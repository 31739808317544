@value vGutter, vRadius, vPrimary1, vGray15 from "../../../../styles/variables.css";

.header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.title {
    display: flex;
    align-items: center;
    padding-right: 8px;
    min-height: 48px;
    font-size: 1.125rem;
    line-height: 1.2;
}

.chevronWrap {
    display: flex;
    align-items: center;
}

.chevron {

}
