@value vGutter, vRadiusSmall, vWhite1, vGray3 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.header {
    min-height: 48px;
    padding: 0 10px;
    border-bottom: 1px solid vWhite1;
    border-radius: vRadiusSmall vRadiusSmall 0 0;
}

.headerText {
    font-size: 1.25rem;
    line-height: 48px;
    color: #000;
}

.colorPreview {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 1px solid vGray3;
}

.colorInput {
    padding: 0;
    text-align: center;
}

.saturationPointer {
    background-color: transparent !important;
    border: 2px solid #fff;
}

.huePointer {
    top: 13px;
    width: 36px !important;
    height: 36px !important;
}

.groupItem + .groupItem {
    margin-left: 28px;
}

.fieldWrap {
    width: 100%;
    padding: 0 8%;
}

.modalCatalogTitle {
    padding-left: 20px;
}

.modalCatalogBody {
    padding: 20px 0 60px 40px;
}

@media tablet-landscape-up {
    .header {
        min-height: 64px;
        padding: 0 25px;
    }

    .headerText {
        line-height: 64px;
    }
}
