@value vRadiusSmall, vRadiusMiddle from "../../styles/variables.css";

.list {
    margin: 0;
    min-width: 180px;
    background-color: #fff;
    list-style: none;
}

.list_normal {
    padding: 0;
    border-radius: vRadiusSmall;
}

.list_large {
    padding: 0 15px;
    border-radius: vRadiusMiddle;
}
