@value vRadiusSmall, vWhite1, vViolet3 from "../../styles/variables.css";

.suggest {
    position: relative;
}

.field {

}

.field_arrow {
    padding-right: 40px;
}

.field_up {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.fieldWrap {
    position: relative;
}

.fieldArrow {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
}

.fieldArrow_up {
    transform: translateY(-50%) rotate(180deg);
}

.options {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    min-height: 50px;
    padding: 0 4px 4px;
    background-color: vWhite1;
    border-radius: 0 0 vRadiusSmall vRadiusSmall;
    z-index: 10;
}

.options:not(.options_active) {
    display: none;
}

.list {
    composes: scrollbar from "../../styles/modules/common.module.css";
    max-height: 150px;
    margin: 0;
    padding: 8px 0;
    background-color: #fff;
    border-radius: inherit;
    list-style: none;
    overflow-y: auto;
}

.noData {
    padding: 10px;
    background-color: #fff;
    text-align: center;
}

.iconArrow svg * {
    fill: #000;
}
