@value vPrimary3, vWhite1, vGray1, vGray11, vGray14 from "../../styles/variables.css";

.wrap {
    position: relative;
    display: flex;
    align-items: center;
}

.wrap_user {
    flex-direction: column;
}

.wrap_business {
    text-decoration: none;
}

.linkEdit {
    position: absolute;
    top: 20px;
    right: 20px;
    outline: none;
}

.linkEdit_active {
    visibility: hidden;
}

.user {

}

.user_user {
    padding: 8px 0 0;
}

.user_business {
    padding: 0 0 0 10px;
}

.userName {
    line-height: 1;
}

.userName_user {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
}

.userName_business {
    font-size: .875rem;
    font-weight: 600;
    color: vGray11;
}

.userEmail {
    padding-top: 6px;
    font-size: .6875rem;
    line-height: 1;
    color: vGray14;
}

.data {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.dataItem {
    display: flex;
    justify-content: flex-end;
    flex-basis: 50%;
    padding: 0 35px 0 0;
}

.dataItem + .dataItem {
    justify-content: flex-start;
    border-left: 2px solid vWhite1;
    padding: 0 0 0 35px;
}

.dataIcon {
    display: block;
    height: 20px;
    flex-shrink: 0;
}

.dataIconImg {
    max-width: 100%;
    max-height: 100%;
}

.dataIcon + .dataText {
    padding-left: 10px;
}

.dataText {
    font-size: .875rem;
    line-height: 1.45;
    color: vGray1;
}

.text {
    margin-top: 14px;
    text-align: center;
    color: #000;
}

.online {
    margin-top: 16px;
    text-align: center;
}

.onlineText,
.onlineTime {
    font-size: 1rem;
    line-height: 1;
    color: vGray1;
}

.onlineText + .onlineTime {
    padding-left: 5px;
}

.onlineTime {
    font-weight: 700;
}
