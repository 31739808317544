@value vWhite1, vGray22 from "../../../../styles/variables.css";

.item {
    composes: _reset from "../../../../components/Button/Button.module.css";
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 3px;
    min-width: 26px;
    height: 26px;
    background-color: vWhite1;
    border-radius: 6px;
    font-size: .75rem;
    line-height: 1;
}

.item:disabled {
    cursor: default;
}

.item + .item {
    margin-left: 6px;
}

.item_active {
    background-color: vGray22;
    cursor: default;
}
