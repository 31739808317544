@value vGray10, vGray14, vGray16, vGray17 from "../../../../styles/variables.css";

.preview {
    display: flex;
    align-items: flex-start;
    position: relative;
}

.preview_draggable {
    cursor: pointer;
}

.imageWrap {
    width: 140px;
    border: 1px solid vGray10;
    border-radius: 3px;
}

.image {
    object-fit: cover;
    height: 100%;
    border-radius: inherit;
}

.drag {
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    width: 11px;
    background-color: vGray17;
    border-radius: 3px 0 0 3px;
}

.dragRow + .dragRow {
    margin-left: 2px;
}

.dragDot {
    width: 2px;
    height: 2px;
    background-color: vGray14;
    border-radius: 50%;
}

.dragDot + .dragDot {
    margin-top: 2px;
}

.action {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: vGray16;
    border-radius: 50%;
}

.delete {
    composes: close from "../../../../styles/modules/close.module.css";
    width: 12px;
    height: 12px;
}

.delete:before,
.delete:after {
    background-color: #fff;
}

.delete:before {
    width: 12px;
}

.delete:after {
    height: 12px;
}
