@value vMenuNavMobileHeight, vBoxShadow, vWhite1, vGray14 from "../../styles/variables.css";

.menuNav {
    margin: 0;
    padding: 0;
    list-style: none;
}

.menuNav_mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: vMenuNavMobileHeight;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: vWhite1;
    box-shadow: vBoxShadow;
    z-index: 15;
}

.icon svg * {
    fill: vGray14;
}
