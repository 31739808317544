@value vGutterX, vRadiusSmall, vGray22 from "../../../styles/variables.css";
@value tablet-up from "../../../styles/breakpoints.css";

.block {
    padding: 12px 20px;
}

.block_gray {
    background-color: vGray22;
}

.blockHeader {
    min-height: 48px;
    align-items: center;
}

.blockHeaderInner {
    padding: 8px 15px;
    align-items: center;
    background-color: vGray22;
    border-radius: vRadiusSmall;
}

.blockTitle {
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 500;
}

.blockActions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.blockAction {
    margin-left: 8px;
}

.blockActionText {

}

.blockActionText_large {
    display: none;
}

.blockActionText_shot {

}

.blockActionSettings {
    margin-left: 30px;
}

.blockBody {
    padding: 15px 0 0;
}

.filterFormBody {
    composes: block from "../../../layout/Block/Block.module.css";
    padding: 20px 15px 30px;
}

.filterFormFooter {
    padding: 25px 0 20px;
}

.divider {
    margin-top: calc(vGutterX / 2);
}

.pagination {
    margin-top: 25px;
}

@media tablet-up {
    .blockActionText_large {
        display: initial;
    }

    .blockActionText_shot {
        display: none;
    }
}
