@value vGutterX, vGray2 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {

}

.updating {
    composes: updating from "../../styles/modules/common.module.css";
}

.orders {
    margin-top: calc(vGutterX / 2);
    padding: 24px 0 0;
    overflow-x: auto;
}

.list {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.listCol {
    padding: 12px 6px;
    background-color: vGray2;
    text-align: left;
}

@media tablet-landscape-up {
    .listCol:first-child {
        padding-left: 24px;
    }

    .listCol:last-child {
        padding-right: 24px;
    }
}
