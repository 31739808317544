@value vGutterX, vRadiusSmall, vGray22, vGray24 from "../../../styles/variables.css";
@value tablet-landscape-up from "../../../styles/breakpoints.css";
@value vBlockGap: calc(15px * 2);

.cardContainer {
    margin-bottom: calc(vGutterX / 2);
}

.blockCard {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 100%;
    padding: 15px 15px 20px;
}

.blockCardHeader {
    padding: 10px 0 0;
}

.blockCardBody {
    padding: 22px 0 0;
}

.block {
    padding: 12px;
}

.block + .block {
    margin-top: calc(vGutterX / 2);
}

.block_gray {
    background-color: vGray22;
}

.blockHeader {
    min-height: 48px;
    align-items: center;
}

.blockHeaderInner {
    padding: 15px;
    align-items: center;
    background-color: vGray22;
    border-radius: vRadiusSmall;
}

.blockHeaderInner_gray {
    background-color: vGray24;
}

.blockHeaderInfo {
    margin-left: auto;
    line-height: 1;
}

.blockActions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.blockTitle {
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 500;
}

.blockBody {
    padding: 10px 0 0;
}

.comment {
    padding: 0 0 15px;
    font-size: .875rem;
}

.modalHeader {
    padding-top: 20px;
}

.modalTitle {
    font-size: 1.125rem;
    font-weight: 500;
    text-transform: none;
}

.modalBody {
    padding: 10px 15px 30px;
}

@media tablet-landscape-up {
    .block {
        padding: 12px 20px;
    }

    .blockCard {
        flex-basis: calc((100% - vBlockGap) / 3);
        max-width: calc((100% - vBlockGap) / 3);
    }

    .modalHeader {
        padding-top: 30px;
    }

    .modalBody {
        padding: 10px 20px 30px;
    }
}
