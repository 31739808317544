@value vHoverTransition, vGray13 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.breadcrumbs {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    overflow: auto hidden;
}

.item {
    display: flex;
    align-items: center;
}

.separator {
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
}

.link,
.text {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
}

.link {
    color: vGray13;
    text-decoration: none;
    transition: color vHoverTransition;
}

.link:hover {
    color: #000;
}

.text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media tablet-landscape-up {
    .item:last-child {
        overflow: hidden;
    }

    .link,
    .text {
        font-size: 1.25rem;
    }
}
