@value vHoverTransition, vRadiusSmall, vRed0, vGray14, vGray17 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.groupItem + .groupItem {
    margin-left: 30px;
}

.groupItem_flex {
    display: flex;
}

.fieldHead {

}

.fieldHead_button {
    width: auto;
}

.fieldArrow {
    composes: fieldArrow from "../../components/CustomSelect/CustomSelectField/CustomSelectField.module.css";
    position: absolute;
    top: 0;
    right: 12px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.fieldIcon {
    right: 14px;
}

.selectField {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
    position: relative;
}

.selectField_heightAuto {
    height: auto;
}

.selectFieldWrapTag {
    display: flex;
    flex-wrap: wrap;
    min-height: 40px;
    padding: 5px 0;
    gap: 5px;
}

.selectOptions {

}

.switch {
    margin-left: auto;
}

.tag {
    padding: 4px 6px;
    background-color: #fff;
    border: 1px solid vGray17;
    border-radius: vRadiusSmall;
    color: #000;
}

.tooltip {
    display: flex;
    align-items: start;
    margin-left: 5px;
}

.tooltipHint {
    min-width: 120px;
}

.tooltipHint_widthAuto {
    width: auto;
}

.textCharCount {
    padding: 5px 0 0;
    font-size: .75rem;
    line-height: 1;
    color: vGray14;
}

.textCharCount_danger {
    color: vRed0;
}

.actions {

}

.modalCatalogTitle {
    padding-left: 40px;
}

.modalCatalogBody {
    padding: 20px 0 60px 40px;
}

.modalCalendarTitle {

}

.modalCalendarBody {
    padding: 0 0 15px;
}

.modalCrop {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
}

.modalCropBody {
    max-height: 100%;
    padding: 0;
    overflow: hidden;
}

.iconCalendar svg path {
    stroke: vGray14;
}

@media tablet-landscape-up {
    .tooltipHint:not(.tooltipHint_widthAuto) {
        width: 400px;
    }
}
