@value vGray2 from "../../styles/variables.css";

.list {

}

.item {
    display: flex;
}

.item,
.loader,
.noResult {
    min-width: 240px;
}

.noResult {
    padding: 24px 8px 20px 8px;
    font-size: .875rem;
    line-height: 1;
    cursor: pointer;
    text-align: center;
}

.symbol {
    padding: 4px 8px;
    flex-basis: 52px;
    background-color: vGray2;
    border-radius: 4px;
    text-align: center;
}

.desc {
    padding: 0 0 0 20px;
    font-size: .875rem;
}
