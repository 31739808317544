@value vTopNavHeight, vPageVerticalGapTop, vGray10, vGray14, vGray17 from "../../../styles/variables.css";

.blockSideBar {
    min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
    overflow: hidden;
}

.blockSideBar_updating {
    composes: updating from "../../../styles/modules/common.module.css";
}

.searchSideBar {
    padding: 20px 15px;
}

.searchListTitle,
.searchListItem {
    padding-left: 15px;
    padding-right: 15px;
}

.splitter {
    margin: 0 20px;
    border-bottom: 1px solid vGray17;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    border-bottom: 1px solid vGray17;
}

.tags_updating {
    composes: updating from "../../../styles/modules/common.module.css";
}

.tag {
    margin: 5px 5px 0;
    padding: 5px 2px;
}

.itemUser {

}
