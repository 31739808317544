@value vGutterX, vGray14 from "../../styles/variables.css";
@value tablet-landscape-up, desktop-up from "../../styles/breakpoints.css";
@value vFieldGap: vGutterX;

.fieldItem {
    flex-basis: 100%;
    max-width: 100%;
}

.fieldItem_column2 {

}

.fieldItem_column3 {

}

.fieldHead {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.fieldHeadActions {
    margin-left: auto;
}

.fieldTitle {
    max-width: 60%;
}

.fieldSelect {

}

.fieldSelect_field {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
    font-size: 1rem;
}

.fieldSelect_lang {
    height: auto;
    font-size: .875rem;
    font-weight: 500;
    text-transform: uppercase;
}

.fieldOption_lang {
    text-transform: uppercase;
}

.fieldSelectPls {
    color: vGray14;
}

.tooltip {
    margin-left: 3px;
}

.tooltipHint {
    min-width: 120px;
}

.controls {
    padding: 30px 0 0;
}

.control {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

@media tablet-landscape-up {
    .fieldItem_column2 {
        flex-basis: calc((100% - vGutterX) / 2);
        max-width: calc((100% - vGutterX) / 2);
    }

    .fieldItem_column3 {
        flex-basis: calc((100% - calc(vGutterX * 2)) / 3);
        max-width: calc((100% - calc(vGutterX * 2)) / 3);
    }

    .fieldTitle {
        max-width: initial;
    }
}

