.catalog {
    padding: 20px 0 0;
}

.catalogEditAction {
    position: absolute;
    top: 8px;
    right: 12px;
    width: auto;
}
