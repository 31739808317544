@value vViolet8 from "../../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../../styles/breakpoints.css";

.block {
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-height: 180px;
    background: linear-gradient(160deg, #35201f 0%, #8661ef 100%);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.amount {
    font-size: 2rem;
    line-height: 1;
    color: #fff;
    white-space: nowrap;
}

.name {
    font-size: 1.125rem;
    line-height: 1;
    color: vViolet8;
    text-align: right;
}

.desc {
    padding: 10px 0 0;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
}

.actions {
    margin-top: auto;
}

.action {

}

.action_btn {
    margin-left: auto;
    margin-right: auto;
    max-width: 212px;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
}

@media tablet-landscape-up {
    .amount {
        font-size: 2.5rem;
    }

    .name {
        font-size: 2rem;
    }

    .desc {
        font-size: 1.125rem;
    }
}
