@value vGutter, vRadiusMiddle, vGray10 from "../../../styles/variables.css";
@value tablet-landscape-up from "../../../styles/breakpoints.css";

.content {

}

.sidebar {

}

.sidebarTitle {
    padding: 0 0 20px;
    font-size: 1.25rem;
    line-height: 1;
    font-weight: 500;
}

.block {

}

.block_sidebar {
    padding: 30px;
}

@media tablet-landscape-up {

}
