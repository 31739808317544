.logoField {
    cursor: pointer;
}

.logoWrap {
    width: 180px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    overflow: hidden;
}

.logoImage {

}
