@value vGray4 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.content {

}

.pageTitle {

}

.block {
    max-width: 100%;
    padding: 20px 24px;
}

@media tablet-up {

}
