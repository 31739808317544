@value vGray14, vBlue11 from "../../../../../styles/variables.css";

.wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.avatar {
    margin-left: auto;
    margin-right: auto;
    /*margin: 30px auto 0;*/
}

.user {
    padding: 16px 0 0;
}

.userName,
.userSurname {
    font-size: 1.125rem;
    line-height: 1.4;
    text-align: center;
}

.data {
    padding: 24px 0 0;
}

.dataAccount,
.dataMember {
    font-size: .875rem;
    line-height: 1.2;
    color: vGray14;
    text-align: center;
}

.actions {
    margin-top: auto;
    padding: 20px 0 0;
}

.action {
    margin-left: auto;
    margin-right: auto;
    max-width: 260px;
    background-color: vBlue11;
}
