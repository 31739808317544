@value vWhite1, vGray1, vGray3 from "../../../../styles/variables.css";

.item {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
}

.item_active {
    background-color: vWhite1;
    border-color: vGray3;
}

.radio {
    margin-top: 10px;
}

.itemBody {
    padding: 8px 0;
    line-height: 1.25;
}

.time {
    color: vGray1;
}

.info {

}

.infoItem + .infoItem {
    margin-left: 8px;
}
