@value vGray3, vBlue6 from "../../../../styles/variables.css";

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 14px;
    max-width: 60px;
    height: 44px;
    overflow: hidden;
}

.item_clickable {
    cursor: pointer;
}

.item_active {
    border-top: 2px solid vGray3;
    background-color: vBlue6;
}

.img {
    max-width: 100%;
    height: auto;
}
