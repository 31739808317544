.block {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
}

.body {
    padding: 0;
}

.currencyDropDown {
    position: absolute;
    top: 22px;
    right: 24px;
}

.currencyTitle {
    padding: 6px;
    text-align: center;
    font-weight: 500;
}

.currencyList {
    composes: scrollbar from "../../../../../styles/modules/common.module.css";
    margin-top: 6px;
    max-height: 300px;
    overflow-y: auto;
}
