@value vGray14 from "../../styles/variables.css";

.pagination {
    display: flex;
}

.pagination_center {
    justify-content: center;
}

.pagination_right {
    justify-content: flex-end;
}

.arrow {
    width: 8px;
    height: 8px;
}

.arrow_disabled {
    border-color: vGray14;
}
