@value vGray4 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {

}

.pageTitle {
    display: flex;
}

.header {

}

.headerAction {
    margin-left: auto;
}

.actionSelect {
    max-width: 200px;
}

.block {
    padding: 20px 10px;
}

.selectItemActive {
    pointer-events: none;
    color: vGray4;
    cursor: default;
}

.selectItemIconWrap {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.selectItemText {
    margin-left: 10px;
}

@media tablet-landscape-up {
    .block {
        padding-left: 30px;
        padding-right: 30px;
    }
}
