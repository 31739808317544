@value vPrimary3, vWhite1, vWhite2, vGray1, vViolet1, vBlue1 from "../../../../styles/variables.css";
@value big-desktop-up from "../../../../styles/breakpoints.css";

.item {
    position: relative;
    padding: 15px 24px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.item + .item {
    margin-left: 10px;
}

.active {
    cursor: default;
}

.active:after {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: -1px;
    height: 3px;
    background: linear-gradient(90deg, vViolet1, vBlue1);
    border-radius: 4px;
    content: '';
}

.center {
    justify-content: center;
}

.marker {
    display: none;
    margin-right: 16px;
    width: 17px;
    height: 17px;
    flex-shrink: 0;
    border: 1px solid vPrimary3;
    border-radius: 50%;
}

.markerWhite {
    background-color: #fff;
}

.markerBlue {
    background-color: vPrimary3;
}

.text {
    font-size: 1.125rem;
    line-height: 1;
    color: #000;
    white-space: nowrap;
}

@media big-desktop-up {
    .item {
        padding: 12px 14px 13px;
        width: 100%;
        background-color: vWhite1;
        border: 1px solid vGray1;
        border-radius: 8px;
        transition: background-color .3s ease, border-color .3s ease;
    }

    .active:after {
        display: none;
    }

    .item:hover,
    .active {
        background-color: vWhite2;
        border-color: vPrimary3;
    }

    .marker {
        display: initial;
    }

    .text {
        font-size: 1rem;
    }
}
