@value vPrimary3, vGray1, vRadius, vRadiusExtraSmall from "../variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {
    padding: 12px;
    border-radius: vRadius;
}

.user {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    color: #000;
}

.user_colorWhite {
    color: #fff;
}

.user_colorGray {
    color: vGray1;
}

.image {
    padding: 0 0 6px;
}

.image > img {
    max-height: 420px;
    border-radius: vRadiusExtraSmall;
    object-fit: contain;
    width: auto;
}

.video,
.video + div {
    border-radius: vRadiusExtraSmall;
}

.image > img,
.video {
    cursor: pointer;
}

.text {
    line-height: 1.25;
    color: #000;
    word-break: break-word;
}

.text_colorWhite {
    color: #fff;
}

.text_colorGray {
    color: vGray1;
}

.date {
    padding: 6px 0 0;
    font-size: .75rem;
    line-height: 1;
}

.date_right {
    text-align: right;
}

.date_colorWhite {
    color: #fff;
}
