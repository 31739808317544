@value vRadiusMiddle, vGreen9 from "../../styles/variables.css";

.field {

}

.fieldText {
    font-size: .875rem;
}

.fieldValue {
    font-size: 1.25rem;
}

.fieldText + .fieldValue {
    margin-top: 4px;
}

.fieldValueMore {
    padding: 0 0 0 10px;
    font-size: .875rem;
}

.fieldSelect {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
    font-size: 1rem;
}

.tag {
    min-width: 130px;
    padding: 10px;
    justify-content: center;
    background-color: vGreen9;
    border-radius: vRadiusMiddle;
}

.tagContent {
    font-size: 1rem;
}
