@value vGutterX, vGray11 from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.auth {
    max-width: 100%;
    margin-left: calc(vGutterX / 2);
    margin-right: calc(vGutterX / 2);
}

.auth_user {
    background-color: #fff;
    border-radius: 4px;
}

.auth_business {

}

.header {

}

.header_user {
    padding: 20px 30px 10px;
    border-bottom: 1px solid #dedede;
}

.header_business {

}

.headerDesc {
    padding: 10px 0 0;
    font-size: 14px;
    text-align: center;
}

.headerDesc_user {
    color: vGray11;
}

.headerDesc_business {
    padding: 60px 0 15px;
}

.body {

}

.body_user {
    padding: 20px 30px;
}

.body_business {
    padding: 0;
}

.bodyDesc {
    font-size: .875rem;
    text-align: center;
}

.bodyDesc_user {
    padding: 10px 0;
    color: vGray11;
}

.bodyDesc_business {
    padding: 60px 0 15px;
}

.footer {
    display: block;
    padding: 25px 30px;
    background-color: #f7f9fd;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    text-decoration: none;
}

.footerDesc {
    font-weight: 600;
    color: #000;
    text-align: center;
}

.footerLink {
    margin-top: 20px;

    color: #82aefc;
    text-align: center;
}

.logoWrap {

}

.logoWrap_user {
    height: 100px;
}

.logoWrap_business {
    height: 92px;
}


.logo {
    width: 100%;
    height: 100%;
}

.timer,
.confirm {
    padding: 20px 0 0;
}

.errorField {
    padding-top: 20px;
    padding-bottom: 20px;
}

.apps {
    display: flex;
    justify-content: space-between;
}

.apps_user {
    padding: 10px 30px 20px;
}

.apps_business {
    padding: 40px 0 20px;
}

.appLink {
    min-height: 40px;
}

.appLink + .appLink {
    margin-left: 10px;
}

@media tablet-up {
    .auth_user {
        width: 350px;
    }

    .auth_business {
        width: 320px;
        margin-left: 100px;
        margin-right: 100px;
    }
}
