@value vRadiusSmall, vHoverTransition, vBlue6, vGray17 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.groupItem + .groupItem {
    margin-left: 30px;
}

.groupItem_flex {
    display: flex;
}

.fieldLabel {
    margin-bottom: 18px;
    width: fit-content;
    cursor: pointer;
}

.fieldArrow {
    composes: fieldArrow from "../../components/CustomSelect/CustomSelectField/CustomSelectField.module.css";
    position: absolute;
    top: 0;
    right: 14px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.actions {

}

.action + .action {
    margin-top: 10px;
}

.tooltip {
    display: flex;
    align-items: start;
    margin-left: 5px;
}

.tooltipHint {
    min-width: 120px;
}

.tooltipHint_widthAuto {
    width: auto;
}

.fileDropzone {
    display: inline-flex;
    align-items: center;
    min-width: 155px;
    min-height: 126px;
    padding: 8px;
    border: 1px dashed vGray17;
    border-radius: vRadiusSmall;
    transition: border-color vHoverTransition;
}

.fileDropzone_active {
    border-color: #000;
}

.fileDropzone_clickable {
    cursor: pointer;
}

.fileDropzoneText {
    margin-left: auto;
    margin-right: auto;
    width: 104px;
    font-size: 1.125rem;
    line-height: 1.375;
    text-align: center;
    color: vGray17;
    transition: color vHoverTransition;
}

.fileDropzoneText_active {
    color: #000;
}

.iconAddPhoto {
    composes: cross from "../../styles/modules/common.module.css";
    width: 20px;
    height: 20px;
    margin-left: 9px;
}

.iconAddPhoto:before,
.iconAddPhoto:after {
    background-color: vBlue6;
}

.iconAddPhoto:before {
    width: 20px;
    height: 2px;
}

.iconAddPhoto:after {
    width: 2px;
    height: 20px;
}

.modalCatalogTitle {
    padding-left: 20px;
}

.modalCatalogBody {
    padding: 20px 0 60px 40px;
}

.modalCrop {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
}

.modalCropBody {
    max-height: 100%;
    padding: 0;
    overflow: hidden;
}

@media tablet-landscape-up {
    .actions {
        display: flex;
    }

    .action:first-child {
        order: 2;
        margin-left: 15px;
    }

    .action:last-child {
        order: 1;
        margin-top: 0;
    }

    .tooltipHint:not(.tooltipHint_widthAuto) {
        width: 400px;
    }
}
