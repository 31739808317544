@value vBlue11 from "../../../../../styles/variables.css";

.wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.actions {
    margin-top: auto;
    padding: 20px 0 0;
    text-align: center;
}

.action {
    color: vBlue11;
}
