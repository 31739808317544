@value vGutter, vGutterX, vGutterXBusiness, vPageVerticalGapTop, vTopNavHeight, vMenuNavMobileHeight from "../../styles/variables.css";
@value tablet-up, tablet-landscape-up, desktop-up, big-desktop-up from "../../styles/breakpoints.css";

.content {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    padding-bottom: calc(vMenuNavMobileHeight + vGutter);
    min-height: calc(100vh - vTopNavHeight - vPageVerticalGapTop);
}

.content_full {
    width: 100%;
}

@media tablet-up {
    .content {
        padding-left: calc(vGutterX / 2);
        padding-right: calc(vGutterX / 2);
    }
}

@media tablet-landscape-up {
    .content {
        padding-bottom: vGutterX;
    }

    .content_half,
    .content_three-quarters {
        width: 70%;
    }
}

@media desktop-up {
    .content_half {
        width: 50%;
    }

    .content_three-quarters {
        width: 75%;
    }
}

@media big-desktop-up {

}
