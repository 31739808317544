@value vRadiusMiddle from "../../../../styles/variables.css";

.wallet {
    padding: 20px;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    border-radius: vRadiusMiddle;
    cursor: pointer;
}

.title {
    font-size: 1rem;
    line-height: 1;
    text-transform: uppercase;
}

.amount {
    padding: 10px 0 0;
    font-size: 2rem;
    line-height: 1;
}
