@value vHoverTransition from "../../styles/variables.css";

.tooltip {
    display: flex;
    align-items: start;
    margin-left: 5px;
}

.tooltipHint {
    min-width: 80px;
}

.control {
    margin: 20px auto 0;
    width: 75%;
    background-color: transparent;
    transition: background-color vHoverTransition;
}

.control:hover {
    background-color: rgba(255, 255, 255, .1);
}
