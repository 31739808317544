@value vPrimary3, vGray4, vGray14, vRed1, vViolet1 from "../../../../styles/variables.css";

.image {
    cursor: pointer;
}

.body {
    padding: 12px 12px 16px;
}

.title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.desc {
    font-size: .875rem;
    font-weight: 400;
    color: vGray14;
    text-align: left;
}

.prices {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: 20px;
}

.pricesItem {
    display: flex;
    flex-wrap: wrap;
}

.pricesItem + .pricesItem {
    margin-top: 4px;
}

.pricesItem_between {
    justify-content: space-between;
    align-items: baseline;
}

.price {
    font-size: 1.125rem;
    line-height: 1;
    color: #000;
    white-space: nowrap;
}

.price_old {
    position: relative;
    margin-left: 10px;
    color: vGray4;
}

.price_old:after {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: vGray4;
    content: '';
}

.price_cv {
    color: vViolet1;
}

.price_kicks {
    color: vPrimary3;
}

.actions {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    min-height: 40px;
}

.action {
    composes: clickable from "../../../../styles/modules/common.module.css";
    position: relative;
    font-size: 0;
}

.action + .action {
    margin-left: 40px;
}

.actionBadge {
    position: absolute;
    top: -5px;
    right: -15px;
}

.actionCounter {
    max-width: 100%;
}

.actionCounter + .action {
    margin-left: 8px;
}

.iconPrice {
    display: inline-block;
    vertical-align: top;
}

.iconPrice {
    width: 15px;
    height: 17px;
}

.iconBuy,
.iconPresent {
    width: 30px;
    height: 30px;
}

.iconPresent svg line,
.iconPresent svg path {
    stroke: #000;
}
