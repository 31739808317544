@value vHoverTransition, vRadiusSmall, vRed0, vGray14, vGray17 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.groupItem {

}

.groupItem + .groupItem {
    margin-left: 15px;
}

.groupItem_flex {
    display: flex;
}

.fieldIcon {
    right: 14px;
}

.selectField {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
    position: relative;
}

.selectOptionText {
    font-size: 1rem;
}

.selectField_heightAuto {
    height: auto;
}

.selectFieldWrapTag {
    display: flex;
    flex-wrap: wrap;
    min-height: 40px;
    padding: 5px 0;
    gap: 5px;
}

.selectOptions {

}

.selectOptionsList {
    max-height: 240px;
}

.switch {
    margin-left: auto;
}

.tag {
    padding: 4px 6px;
    background-color: #fff;
    border: 1px solid vGray17;
    border-radius: vRadiusSmall;
    color: #000;
}

.tooltip {
    display: flex;
    align-items: start;
    margin-left: 5px;
}

.tooltipHint {
    min-width: 120px;
}

.tooltipHint_widthAuto {
    width: auto;
}

.textCharCount {
    padding: 5px 0 0;
    font-size: .75rem;
    line-height: 1;
    color: vGray14;
}

.textCharCount_danger {
    color: vRed0;
}

.actions {
    display: flex;
}

.action + .action {
    margin-left: 10px;
}

.modalCatalogTitle {
    padding-left: 40px;
}

.modalCatalogBody {
    padding: 20px 0 60px 40px;
}

.modalCalendarTitle {

}

.modalCalendarBody {
    padding: 0 0 15px;
}

.modalCrop {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
}

.modalCropBody {
    max-height: 100%;
    padding: 0;
    overflow: hidden;
}

.iconCalendar svg * {
    fill: vGray14;
}

@media tablet-landscape-up {
    .groupItem + .groupItem {
        margin-left: 30px;
    }

    .tooltipHint:not(.tooltipHint_widthAuto) {
        width: 400px;
    }
}
