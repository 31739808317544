.item {
    display: flex;
    align-items: center;
    width: auto;
}

.name {
    font-size: 1.125rem;
    line-height: 1;
}

.iconItem {
    margin-right: 16px;
}
