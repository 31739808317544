@value vGutter, vRadiusSmall, vBlue11, vGray3, vGray6 from "../../../../../styles/variables.css";
@value desktop-up from "../../../../../styles/breakpoints.css";

.header {

}

.headerTitle {

}

.body {

}

.footer {
    display: block;
    padding: 6px 0;
    background-color: vGray6;
    border-radius: vRadiusSmall;
}

.row {

}

.col {
    padding: 10px;
    width: 50%;
    font-size: .875rem;
    line-height: 1.2;
}

.col_mobile_hidden {
    display: none;
}

.col_header {
    vertical-align: middle;
}

.col_body {

}

.col_footer {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
}

.colTitle {
    padding: 0 0 5px;
    font-weight: 500;
}

.colValue {
    white-space: nowrap;
}

.link {
    color: #000;
}

.link_blue {
    color: vBlue11;
    text-decoration: none;
}

@media desktop-up {
    .footer {
        display: table-footer-group;
        background-color: initial;
    }

    .row {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .row > .col_body {
        border-top: 1px solid vGray3;
    }

    .col {
        padding: 15px;
        width: auto;
    }

    .col_mobile_hidden {
        display: table-cell;
    }

    .col_footer {
        background-color: vGray6;
    }

    .col_footer:first-child {
        border-radius: vRadiusSmall 0 0 vRadiusSmall;
    }

    .col_footer:last-child {
        border-radius: 0 vRadiusSmall vRadiusSmall 0;
    }

    .colTitle {
        display: none;
    }
}
