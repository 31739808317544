@value vGray13, vGray14 from "../../../../../styles/variables.css";

.title {
    font-size: 1.125rem;
}

.empty {
    font-size: .875rem;
    line-height: 1;
    color: vGray13;
}

.tag {
    background-color: #000;
    border-radius: 3px;
}

.tag + .tag {
    margin-left: 4px;
}

.tagContent {
    font-size: .6875rem;
    color: #fff;
}

.tagCross {
    width: 8px;
    height: 8px;
}

.tagCross:hover:before,
.tagCross:hover:after {
    background-color: vGray13;
}

.tagCross:before,
.tagCross:after {
    background-color: #fff;
}

.tagCross:before {
    width: 8px;
    height: 1px;
}

.tagCross:after {
    width: 1px;
    height: 8px;
}

.actionMore {
    margin-left: 6px;
    font-size: .75rem;
    color: vGray14;
}
