@value vWhite1, vGray17 from "../../../../styles/variables.css";

.wrap {
    padding: 30px;
    background-color: vWhite1;
}

.image {
    background-color: vGray17;
}

.body {
    padding-top: 20px;
    padding-bottom: 20px;
}

.title {
    font-size: 1.125rem;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row {

}

.row + .row {
    margin: 12px auto 0;
    width: 80%;
}
