@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {

}

.block {
    padding: 20px 10px 20px;
}

@media tablet-landscape-up {
    .block {
        padding: 20px 40px 30px;
    }
}
