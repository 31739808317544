@value vGray4 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {
    position: relative;
}

.headerContent {
    display: flex;
    flex-basis: 100%;
    align-items: center;
}

.headerContentCount {
    padding-left: 8px;
    font-size: 1.25rem;
    line-height: 1;
    color: vGray4;
}

.headerContentActions {
    margin-left: auto;
}

.loaderSearch {
    top: 200px;
    z-index: 110;
}

.modalFiltersOverlay {
    composes: overlay_transparent from "../../styles/modules/overlay.module.css";
}

.iconSearch svg path {
    fill: #000;
}
