@value vGutter, vWhite3, vGray1 from "../../styles/variables.css";

.footer {
    flex: 0 0 auto;
    padding: 10px vGutter;
    background-color: vWhite3;
}

.nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

.navItem + .navItem {
    margin-left: 9px;
}

.navLink {
    color: vGray1;
    text-decoration: none;
    outline: none;
    transition: color .3s ease;
}

.navLink:hover {
    color: #000;
}

.copyright {
    margin-top: 20px;
    font-size: 13px;
    color: #9335e1;
    text-align: center;
    text-transform: uppercase;
}
