@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.block {
    padding: 15px 10px 0;
}

.body {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 15px 0;
}

.menu {
    position: absolute;
    top: 0;
    right: 0;
}

.imageWrap {
    width: 80px;
    height: 60px;
}

.image {
    width: auto;
    max-height: 100%;
}

.name {
    padding: 0 0 0 10px;
    font-size: 1.25rem;
    line-height: 1;
}

.footer {

}

.tabs {

}

.tabs_spaceAround {
    justify-content: space-around;
}

@media tablet-landscape-up {
    .block {
        padding: 25px 20px 0;
    }

    .body {
        padding: 0 0 30px 15px;
    }

    .name {
        padding: 0 0 0 30px;
    }
}
