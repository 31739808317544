@value vRadius, vPrimary3, vGray1, vGray10, vGray12 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.block {
    display: block;
    padding: 12px;
    cursor: pointer;
    transition: border-color .3s ease;
    border: 2px solid vGray12;
    border-radius: vRadius;
}

.block:not(.block_active):hover {
    border-color: vGray10;
}

.block_active {
    border-color: vPrimary3;
    cursor: default;
}

.title {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1;
}

.comment {
    margin-top: 12px;
    line-height: 1.2;
}

.info {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    line-height: 1;
}

.time {
    color: vGray1;
}

.cost {
    font-size: 1.125rem;
}

@media tablet-landscape-up {
    .block {
        padding: 24px 12px;
    }
}
