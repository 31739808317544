@value vGray3 from "../../../../styles/variables.css";
@value desktop-up from "../../../../styles/breakpoints.css";

.header {
    display: none;
    padding-bottom: 10px;
    border-bottom: 1px solid vGray3;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1;
}

.body {

}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
}

.row + .row {
    border-top: 1px solid vGray3;
}

.footer {
    border-top: 1px solid vGray3;
}

.info {
    padding-top: 0;
}

@media desktop-up {
    .header {
        display: flex;
    }

    .row {
        flex-wrap: nowrap;
    }
}
