@value vGutter from "../../styles/variables.css";

.container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.container + .container {
    margin-top: vGutter;
}
