@value vGray1 from "../../../../styles/variables.css";

.group {

}

.group + .group {
    margin-top: 24px;
}

.transaction + .transaction {
    margin-top: 12px;
}

.date {
    padding: 0 0 12px;
    font-size: .875rem;
    color: vGray1;
}
