@value tablet-landscape-up from "../../styles/breakpoints.css";

.container {
    padding: 12px 0;
}

.title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
}

@media tablet-landscape-up {
    .container {
        padding-top: 0;
        padding-bottom: 20px;
    }
}
