@value vWhite1 from "../../styles/variables.css";

.wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 200px;
    padding: 15px 15px 20px;
}

.title {
    color: #000;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.125;
    text-decoration: none;
}

.body {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.bars {
    display: flex;
    align-items: flex-end;
}

.bar {
    width: 16px;
    background-color: vWhite1;
    border-radius: 2px;
}

.bar + .bar {
    margin-left: 2px;
}

.bar_h10 {
    height: 10px;
}

.bar_h30 {
    height: 30px;
}

.bar_h44 {
    height: 30px;
}

.round {
    position: relative;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    border: 1px solid #000;
}

.wrapArrow {
    width: 100%;
    height: 100%;
    transform: rotate(-45deg);
}

.arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 4px;
    font-size: 22px;
    line-height: 0;
    color: #000;
    transform: translate(-50%, -50%);
}
