.button-text {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
}

.image {
    max-width: 100%;
    height: auto;
}

.cropperRound .cropper-view-box,
.cropperRound .cropper-face {
    border-radius: 50%;
}

.hide {
    display: none;
}

.ReactModal__Body--open {
    overflow-y: hidden;
}

.ReactCollapse--collapse {
    transition: height 300ms;
    width: 100%;
}

/* CSSTransition */
.fade-in-enter {
    opacity: 0;
}

.fade-in-enter-active {
    opacity: 1;
    transition: opacity .6s;
}

.fade-in-exit {
    opacity: 1;
}

.fade-in-exit-active {
    opacity: 0;
    transition: opacity .6s;
}

/* global */
/*
.globalGreen:global {
    color: green;
}
*/
