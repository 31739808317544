.item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 6px;
    width: 44px;
    height: 44px;
    cursor: pointer;
}

.img {
    max-width: 100%;
    height: auto;
}
