@value vGutter from "../../../styles/variables.css";
@value tablet-landscape-up from "../../../styles/breakpoints.css";

.block {

}

.form {
    padding: 20px 10px 30px;
}

@media tablet-landscape-up {
    .form {
        padding: 30px 25px 40px;
    }
}
