@value vPrimary2, vGray2, vGray3, vBlue1, vRed3 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.row:hover .col {
    background-color: vGray2;
}

.col {
    padding: 8px 6px;
    border-top: 1px solid vGray3;
    font-size: .875rem;
    line-height: 1.2;
    text-align: left;
    transition: background-color .3s;
}

.text {
    font-size: .875rem;
}

.text_action {
    white-space: nowrap;
}

.text_danger {
    color: vRed3;
}

.link {
    color: vBlue1;
    transition: color .3s ease;
}

.link:hover {
    color: vPrimary2;
}

.product {
    white-space: nowrap;
}

@media tablet-landscape-up {
    .col:first-child {
        padding-left: 24px;
    }

    .col:last-child {
        padding-right: 24px;
    }
}
