@value vGutterX, vGray14, vGray23, vGray24 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";
@value vBlockGap: calc(15px * 2);

.block {
    padding: 10px 15px 20px;
    flex-basis: 100%;
    max-width: 100%;
}

.block_gray {
    background-color: vGray24;
    border: 1px solid vGray23;
}

.blockHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 15px;
}

.blockTitle {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1;
}

.blockAction {
    font-size: .75rem;
}

.blockControl {
    background-color: transparent;
}

.fieldSelect {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
}

.fieldTextBefore {
    padding-right: calc(vGutterX / 2);
}

.fieldIconAction {
    width: auto;
    padding-left: calc(vGutterX / 2);
}

.groupItemRow {
    display: flex;
    align-items: center;
    min-height: 40px;
}

.tabs {
    position: relative;
    margin-bottom: 20px;
    padding: 10px 0 0;
    justify-content: space-between;
}

.tabs:after {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: -2px;
    height: 3px;
    background-color: vGray24;
    content: '';
}

.tabsItem {
    padding: 8px 10px;
    flex-grow: 1;
    text-align: center;
}

.tabsItem:after {
    background-color: #000;
    z-index: 1;
}

.tabsText {
    font-size: .875rem;
    line-height: 1;
    color: #000;
}

.tooltip {
    display: flex;
    align-items: start;
    margin-left: 5px;
}

.tooltipHint {
    min-width: 120px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0 0;
}

.tagsCount {
    margin-top: 7px;
    font-size: .875rem;
    color: vGray14;
    line-height: 1;
}

.tag {
    margin: 5px 4px 0 0;
    border-radius: 3px;
}

.tagContent {
    max-width: 100px;
    font-size: .6875rem;
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tagCross {
    width: 8px;
    height: 8px;
}

.tagCross:before,
.tagCross:after {
    background-color: #fff;
}

.tagCross:before {
    width: 8px;
    height: 1px;
}

.tagCross:after {
    width: 1px;
    height: 8px;
}

.controls {
    padding: 20px 0 0;
}

.control {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.iconCalendar svg * {
    fill: vGray14;
}

@media tablet-landscape-up {
    .block {
        flex-basis: calc((100% - vBlockGap) / 3);
        max-width: calc((100% - vBlockGap) / 3);
    }
}
