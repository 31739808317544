@value vGutter from "../../styles/variables.css";
@value vCardHalfGap: vGutter;
@value vCardThirdGap: calc(vGutter * 2);
@value vCardFourthGap: calc(vGutter * 3);

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    outline: none;
}

.card_link {
    cursor: pointer;
    text-decoration: none;
}

.card_fullWidth {
    max-width: 100%;
    flex-basis: 100%;
}

.card_halfWidth {
    max-width: calc((100% - vCardHalfGap) / 2);
    flex-basis: calc((100% - vCardHalfGap) / 2);
}

.card_thirdWidth {
    max-width: calc((100% - vCardThirdGap) / 3);
    flex-basis: calc((100% - vCardThirdGap) / 3);

}

.card_fourthWidth {
    max-width: calc((100% - vCardFourthGap) / 4);
    flex-basis: calc((100% - vCardFourthGap) / 4);
}
