@value vRadius, vBoxShadow, vBlue7, vGray3, vBlue6 from "../../styles/variables.css";

.stickers {
    position: relative;
}

.trigger {
    position: relative;
    font-size: 0;
    composes: clickable from "../../styles/modules/common.module.css";
}

.popup {
    position: absolute;
    bottom: 100%;
    min-width: 350px;
    margin-bottom: 12px;
    background-color: #fff;
    box-shadow: vBoxShadow;
    border-radius: vRadius;
    z-index: 10;
}

.popup:after {
    position: absolute;
    bottom: -8px;
    width: 16px;
    height: 16px;
    background-color: vBlue7;
    content: '';
    transform: rotate(45deg);
}

.popup_right {
    right: -40px;
}

.popup_right:after {
    right: 48px;
}

.popup_left {
    left: -40px;
}

.popup_left:after {
    left: 48px;
}

.popupBody {
    max-height: 210px;
    overflow-y: auto;
}

.popupFooter {
    background-color: vBlue7;
    border-radius: 0 0 vRadius vRadius;
}
