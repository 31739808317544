@value vGray0 from "../../styles/variables.css";
@value tablet-landscape-up, desktop-up from "../../styles/breakpoints.css";

.block {
    padding: 20px 10px;
}

.nav {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.nav + .nav {
    margin-top: 12px;
}

.icon {
    margin-right: 12px;
}

.iconSettings svg * {
    fill: vGray0;
}

.text {
    color: #000;
    line-height: 1;
}

@media tablet-landscape-up {
    .block {
        padding-left: 30px;
        padding-right: 30px;
    }
}
