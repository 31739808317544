@value vGray14, vGray25 from "../../../../styles/variables.css";
@value tablet-up from "../../../../styles/breakpoints.css";

.filter {
    position: relative;
}

.actions {
    display: flex;
    align-items: center;
}

.action {

}

.action_btn {
    border-color: vGray25;
    border-radius: 12px;
    font-size: .875rem;
    color: #000;
}

.action_btn:not(:disabled):hover {
    background-color: vGray25;
    filter: none;
}

.action_btn_active {
    background-color: vGray25;
    cursor: default;
}

.action + .action {
    margin-left: 8px;
}

.fields {
    display: flex;
    margin-top: 20px;
}

.field {
    position: relative;
    flex-basis: 50%;
}

.field + .field {
    margin-left: 20px;
}

.iconCalendar svg path,
.iconCalendar svg circle {
    fill: vGray14;
}

.iconCalendar svg path:last-of-type {
    fill: none;
    stroke: vGray14;
}

@media tablet-up {
    .action + .action {
        margin-left: 10px;
    }

    .action_btn {
        font-size: 1rem;
    }
}
