@value vDefaultHeight: 110px;

.textarea {
    composes: reset from "Input.module.css";
    padding-top: 12px;
    padding-bottom: 8px;
    height: vDefaultHeight;
    resize: vertical;
}

.textarea_autoheight {
    height: unset;
}

.textarea_noresize {
    resize: none;
}

.default {
    composes: default from "Input.module.css";
}

.defaultBusiness {
    composes: defaultBusiness from "Input.module.css";
}

.dynamic {
    composes: dynamic from "Input.module.css";
    padding-top: 26px;
}

.placeholder {
    composes: placeholder from "Input.module.css";
    top: 18px;
    transform: translateY(0);
}

.transparent {
    composes: transparent from "Input.module.css";
}

.gray {
    composes: gray from "Input.module.css";
}
