@value vRadiusExtraSmall, vGray13 from "../../styles/variables.css";

.fieldSelect {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
    position: relative;
}

.control {
    margin: 0 auto;
    width: 50%;
}

.actionAdd {
    color: #000;
    font-weight: 500;
    text-decoration: none;
}

.control {
    margin: 0 auto;
    width: 50%;
}

.tags {
    padding: 5px 0 0;
    display: flex;
    flex-wrap: wrap;
}

.tagsCount {
    margin-top: 7px;
    font-size: .875rem;
    line-height: 1;
}

.tag {
    margin: 5px 4px 0 0;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: vRadiusExtraSmall;
}

.tagContent {
    max-width: 100px;
    font-size: .6875rem;
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tagCross {
    width: 8px;
    height: 8px;
}

.tagCross:hover:before,
.tagCross:hover:after {
    background-color: vGray13;
}

.tagCross:before,
.tagCross:after {
    background-color: #fff;
}

.tagCross:before {
    width: 8px;
    height: 1px;
}

.tagCross:after {
    width: 1px;
    height: 8px;
}

.iconAdd {
    composes: cross from "../../styles/modules/common.module.css";
    width: 20px;
    height: 20px;
    margin-left: 9px;
}

.iconAdd:before {
    width: 20px;
    height: 2px;
}

.iconAdd:after {
    width: 2px;
    height: 20px;
}
