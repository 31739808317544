@value vGutterX, vGray13, vGray14 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";
@value vBlockGap: calc(15px); /* TODO vGutterX */

.block {
    width: 100%;
}

.field {

}

.fieldSelect {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
}

.fieldSelectText {
    font-size: .875rem;
    color: vGray14;
}

.fieldTags {
    margin-top: 10px;
}

.fieldTag {
    border-radius: 4px;
}

.tag + .tag {
    margin-left: 4px;
}

.tagContent {
    font-size: .875rem;
    color: #fff;
}

.tagCross {
    width: 8px;
    height: 8px;
}

.tagCross:hover:before,
.tagCross:hover:after {
    background-color: vGray13;
}

.tagCross:before,
.tagCross:after {
    background-color: #fff;
}

.tagCross:before {
    width: 8px;
    height: 1px;
}

.tagCross:after {
    width: 1px;
    height: 8px;
}

.control {
    margin: 0 auto;
    width: 50%;
}

.actionAdd {
    width: auto;
}

.actionAddText {
    font-weight: 500;
}

.iconAdd {
    composes: cross from "../../styles/modules/common.module.css";
    width: 20px;
    height: 20px;
    margin-left: 9px;
}

.iconAdd:before {
    width: 20px;
    height: 2px;
}

.iconAdd:after {
    width: 2px;
    height: 20px;
}

@media tablet-landscape-up {
    .block {
        flex-basis: calc((100% - vBlockGap) / 2);
        max-width: calc((100% - vBlockGap) / 2);
    }
}
