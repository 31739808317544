@value vGutter, vRadiusSmall, vRadiusExtraSmall, vHoverTransition, vBlue11, vGray3, vGray6, vGray22, vGray25, vGreen8 from "../../../../../styles/variables.css";
@value tablet-up, desktop-up from "../../../../../styles/breakpoints.css";

.list {
    border-bottom: 1px solid vGray3;
}

.header {

}

.headerTitle {

}

.body {

}

.footer {
    background-color: vGray6;
    border-radius: vRadiusSmall;
}

.row {

}

.col {
    padding: 10px;
    width: 50%;
    font-size: .875rem;
    line-height: 1.2;
}

.col_header {
    vertical-align: middle;
}

.col_headerCenter {
    text-align: center;
}

.col_body {

}

.col_textCenter {
    text-align: center;
}

.colTitle {
    padding: 0 0 5px;
    font-weight: 500;
}

.colInner {
    display: flex;
    flex-direction: column;
    font-size: .875rem;
    line-height: 1;
}

.colInner_center {

}

.rowType {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rowType + .rowType {
    margin-top: 6px;
}

.rowStore {

}

.rowStore:before {
    position: relative;
    padding: 0 8px 0 0;
    content: '•';
}

.rowStore + .rowStore {
    margin-top: 4px;
}

.rowTag {

}

.rowTag + .rowTag {
    margin-top: 3px;
}

.tag {
    background-color: vGreen8;
    padding: 0 4px 0 0;
    border-radius: vRadiusExtraSmall;
}

.tagContent {
    position: relative;
    padding: 6px 15px 6px 6px;
    font-size: .6875rem;
    color: #fff;
}

.tagContent:after {
    position: absolute;
    top: 50%;
    right: 0;
    height: 18px;
    content: '\2192';
    font-size: 1rem;
    line-height: 13px;
    transform: translateY(-50%);
}

.switch {
    width: 30px;
    height: 16px;
}

.switchControl {
    width: 16px;
    height: 16px;
    background-color: vGray22;
}

.switchControl_active {
    background-color: vBlue11;
}

.link {
    color: vBlue11;
    text-decoration: none;
}

.actionLink {
    padding: 4px;
    background-color: #fff;
    border: 1px solid vGray25;
    border-radius: vRadiusExtraSmall;
    font-size: .6875rem;
    line-height: 1;
    color: #000;
    text-decoration: none;
    transition: filter vHoverTransition;
}

.actionLink:hover {
    filter: brightness(.90);
}

@media tablet-up {
    .col {
        width: 33.33%;
    }
}

@media desktop-up {
    .footer {
        background-color: initial;
    }

    .row {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .row > .col_body {
        border-top: 1px solid vGray3;
    }

    .col {
        padding: 15px;
        width: auto;
    }

    .col_footer {
        background-color: vGray6;
    }

    .col_footer:first-child {
        border-radius: vRadiusSmall 0 0 vRadiusSmall;
    }

    .col_footer:last-child {
        border-radius: 0 vRadiusSmall vRadiusSmall 0;
    }

    .colTitle {
        display: none;
    }

    .colInner_center {
        justify-content: center;
    }
}
