@value vGutter, vPrimary1, vViolet1, vBlue1 from "../../styles/variables.css";
@value tablet-landscape-up from "../../styles/breakpoints.css";

.content {
    margin-top: calc(vGutter * 2);
}

.nav {
    margin-top: calc(vGutter * 2);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.tabs {
    padding: 15px 0 0;
}

.tabsItem {
    color: vPrimary1;
}

.tabsItemActive {
    cursor: default;
}

.tabsItemActive:after {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: -1px;
    height: 3px;
    background-color: vPrimary1;
    border-radius: 4px;
    content: '';
}

.feed {
    position: relative;
    flex-grow: 1;
}

@media tablet-landscape-up {
    .tabs {
        padding: 30px 24px 0;
    }
}
