@value vGutterX, vRadiusSmall, vRadiusMiddle, vGray21, vGray22, vGray24, vViolet9 from "../../../styles/variables.css";
@value phone-only, desktop-up from "../../../styles/breakpoints.css";

.block {
    padding: 12px;
}

.block_gray {
    background-color: vGray22;
}

.blockHeader {
    flex-wrap: wrap;
    min-height: 48px;
    align-items: center;
}

.blockHeaderInner {
    padding: 15px;
    align-items: center;
    background-color: vGray22;
    border-radius: vRadiusSmall;
}

.blockTitle {
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 500;
}

.blockActions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.blockAction {
    margin-left: 8px;
}

.blockAction_violet {
    background-color: vViolet9;
    color: #fff;
}

.blockActionSettings {
    margin-left: 30px;
}

.blockBody {
    padding: 10px 0 0;
}

.blockBody_list {
    overflow-y: auto;
}

.filterFormBody {

}

.filterFormFooter {
    padding: 0 0 10px;
}

.divider {
    margin-top: calc(vGutterX / 2);
}

.listSettings {
    margin: 18px 0 0;
    padding: 20px;
    min-width: 256px;
    background-color: vGray22;
    box-shadow: none;
}

.listSettingsItem + .listSettingsItem {
    margin-top: 4px;
}

.presets {
    padding: 0 0 30px;
}

.list {

}

.list_updating {
    composes: updating from "../../../styles/modules/common.module.css";
}

.pagination {
    padding: 10px 0;
}

.modalPresets {
    background-color: vGray24;
}

.modalPresetsHeader {
    padding: 20px 15px 20px;
}

.modalPresetsTitle {
    text-transform: capitalize;
}

.modalPresetsBody {
    padding: 10px 15px 30px;
}

@media phone-only {
    .blockActions {
        margin-top: 10px;
    }
}

@media desktop-up {
    .tableColTitle {
        display: none;
    }
}
