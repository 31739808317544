@value vGutterX from "../../styles/variables.css";
@value tablet-up from "../../styles/breakpoints.css";

.block {
    max-width: 100%;
    margin-bottom: 20px;
    margin-left: calc(vGutterX / 2);
    margin-right: calc(vGutterX / 2);
    padding: 20px 24px;
}

.storeName {
    margin-bottom: 20px;
    text-align: center;
}

@media tablet-up {
    .block {
        width: 350px;
    }
}
