@value vPrimary3, vWhite2 from "../../styles/variables.css";

.item {
    width: 100%;
}

.item + .item {
    margin-top: 1px;
}

.item:hover .body {
    background-color: vWhite2;
}

.item:first-child .body {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.item:not(:last-child) .body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.body {
    flex-direction: row;
}

.avatar {
    position: relative;
}

.content {
    padding-left: 20px;
    overflow: hidden;
}

.online {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: vPrimary3;
    border-radius: 4px;
    content: '';
}

.name {
    font-size: 1.25rem;
    color: #000;
}
