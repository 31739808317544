@value vHoverTransition from "../../styles/variables.css";

.close {
    width: 24px;
    height: 24px;
    transform: rotate(45deg);
}

.close:before,
.close:after {
    position: absolute;
    background-color: #000;
    content: '';
    transition: background-color vHoverTransition;
}

.close:before {
    width: 24px;
    height: 2px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.close:after {
    width: 2px;
    height: 24px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.close:hover:after,
.close:hover:before {
    background-color: rgba(0, 0, 0, .5);
}

.close_white:before,
.close_white:after {
    background-color: #fff;
}

.close_white:hover:after,
.close_white:hover:before {
    background-color: rgba(255, 255, 255, .5);
}
