@value vGray3 from "../../../../styles/variables.css";
@value desktop-up, big-desktop-up from "../../../../styles/breakpoints.css";

.item {
    display: flex;
    padding-bottom: 24px;
}

.item + .item .content {
    padding-top: 16px;
    border-top: 1px solid vGray3;
}

.item + .item .avatar {
    margin-top: 16px;
}

.avatar {

}

.content {
    flex-basis: 100%;
    padding-left: 16px;
}

.content_mobile {
    padding-bottom: 8px;
}

.name {
    padding-bottom: 8px;
}

.nameLink {
    font-weight: 500;
    color: #000;
    text-decoration: none;
}

.actions {
    display: flex;
}

.action {
    font-size: .875rem;
}

.action + .action {
    margin-left: 5px;
}

@media desktop-up {
    .content {
        /*margin-left: 8px;*/
    }

    .action {
        font-size: .8125rem;
    }
}

@media big-desktop-up {
    .content {
        /*padding-left: 16px;*/
    }

    .action {
        font-size: .875rem;
    }
}
