@value vRadiusSmall, vGray13, vGray14, vGray10, vBlue11 from "../../../../styles/variables.css";

.item {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item + .item {
    margin-top: 10px;
}

.empty {
    font-size: .875rem;
    line-height: 1;
    color: vGray13;
}

.title {
    padding: 6px 12px;
    background-color: vGray14;
    border-radius: vRadiusSmall;
    color: #fff;
    font-size: .875rem;
    line-height: 1;
    white-space: nowrap;
}

.actionDelete {
    margin-left: 8px;
}

.actionMore {
    margin-top: 20px;
    font-size: .875rem;
    line-height: 1;
    color: vBlue11;
    text-decoration: underline;
}

.iconTrash svg * {
    fill: #000;
}

.modalPresetsTitle {
    border-bottom: 1px solid vGray10;
    font-size: 1.125rem;
    font-weight: 500;
}

.modalPresetsTitleCount {
    padding-left: 4px;
    color: vGray14;
}

.modalPresetsBody {
    padding-bottom: 40px;
}

.modalPresetsBodyTitle {
    padding: 0 0 20px;
    font-size: 1.125rem;
}
