@value vGutter, vBlue11, vGray3 from "../../../../../styles/variables.css";
@value tablet-up, desktop-up from "../../../../../styles/breakpoints.css";

.list {

}

.header {

}

.headerTitle {

}

.headerSort {

}

.row {

}

.col {
    padding: 10px;
    width: 50%;
    font-size: .875rem;
    line-height: 1.2;
}

.col_header {
    vertical-align: middle;
}

.col_body {

}

.colTitle {
    padding: 0 0 5px;
    font-weight: 500;
}

.colInner {
    display: flex;
    font-size: .875rem;
    line-height: 1;
}

.link {
    color: #000;
}

.link_blue {
    color: vBlue11;
    text-decoration: none;
}

.noDataText {
    margin-top: 0;
}

@media tablet-up {
    .col {
        width: 33.33%;
    }
}

@media desktop-up {
    .row {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .row > .col_body {
        border-top: 1px solid vGray3;
    }

    .col {
        padding: 15px;
        width: 20%;
    }

    .colTitle {
        display: none;
    }
}
