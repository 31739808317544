@value vBoxShadow, vRadiusMiddle from "../../styles/variables.css";

.wrap {
    position: relative;
    display: inline-block;
}

.trigger {
    display: inline-block;
    cursor: default;
}

.tooltip {
    position: absolute;
    background-color: #fff;
    box-shadow: vBoxShadow;
    border-radius: vRadiusMiddle;
    z-index: 10;
}

.tooltip_top {
    bottom: 100%;
    left: 0;
}

.tooltip_bottom {
    top: 100%;
    left: 0;
}

.tooltip_left,
.tooltip_right {
    top: 50%;
    transform: translateY(-50%);
}

.tooltip_left:before,
.tooltip_right:before {
    position: absolute;
    top: 50%;
    width: 15px;
    height: 16px;
    background-color: inherit;
    box-shadow: inherit;
    content: '';
    transform: translateY(-50%) rotate(45deg);
    z-index: -1;
}

.tooltip_left {
    margin-right: 15px;
    right: 100%;
}

.tooltip_left:before {
    right: -8px;
    border-radius: 0 6px 0 0;
}

.tooltip_right {
    margin-left: 15px;
    left: 100%;
}

.tooltip_right:before {
    left: -8px;
    border-radius: 0 0 0 6px;
}

.tooltip_center {

}

.tooltipBody {
    display: flex;
    align-items: center;
    min-height: 42px;
    padding: 5px 15px;
    background-color: #fff;
    border-radius: inherit;
    font-size: .75rem;
    line-height: 1.3;
}
