.header {
    position: relative;
    display: flex;
    padding: 30px 20px 20px;
}

.header_pRight {
    padding-right: 40px;
}

.title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
}

.title_center {
    margin-left: auto;
    margin-right: auto;
}

.title_left {

}

.back {
    width: auto;
    margin-right: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 16px;
    composes: close from "../../../styles/modules/close.module.css";
}
