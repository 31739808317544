@value vHoverTransition, vLoadingOpacity, vPrimary1, vPrimary3, vGray0, vGray1, vGray21 from "../../styles/variables.css";

._reset {
    padding: 0;
    font-size: 1rem;
    line-height: 1;
    border: none;
    outline: none;
    cursor: pointer;
}

.default:disabled,
.bordered:disabled,
.transparent:disabled,
.small:disabled {
    opacity: vLoadingOpacity;
    cursor: default;
}

.default:disabled {
    background-color: vGray0;
    color: vGray1;
}

.bordered:disabled {
    border-color: vGray0;
    color: vGray1;
}

.default:not(:disabled),
.default_bordered:not(:disabled),
.bordered:not(:disabled),
.small:not(:disabled) {
    transition: filter vHoverTransition;
}

.default:not(:disabled):hover,
.default_bordered:not(:disabled):hover,
.bordered:not(:disabled):hover,
.small:not(:disabled):hover {
    filter: brightness(.90);
}

.default {
    composes: _reset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    background-color: vPrimary1;
    border-radius: 30px;
    line-height: 1;
    color: #fff;
    text-decoration: none;
}

.default_bordered,
.bordered {
    border: 1px solid vPrimary1;
    border-radius: 22px;
    color: vPrimary1;
}

.bordered {
    composes: _reset;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fff;
    text-decoration: none;
}

.default_bordered {
    composes: bordered;
}

.small {
    composes: _reset;
    padding-left: 20px;
    padding-right: 20px;
    background-color: vGray21;
    border-radius: 12px;
    font-size: .875rem;
}

.transparent {
    composes: _reset;
    display: flex;
    align-items: center;
    width: 100%;
    background: transparent;
    line-height: 1;
    color: #000;
    text-decoration: none;
}

.custom {
    composes: _reset;
}

.text {
    composes: _reset;
    background: none;
}

.textUpper {
    text-transform: uppercase;
}

.size30 {
    height: 30px;
}

.size32 {
    height: 32px;
}

.size36 {
    height: 36px;
}

.size40 {
    height: 40px;
}

.size44 {
    height: 44px;
}

.size46 {
    height: 46px;
}

.size48 {
    height: 48px;
}

.size52 {
    height: 52px;
}

.size60 {
    height: 60px;
}
