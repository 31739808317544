@value vGray1, vGray10 from '../../styles/variables.css';

.footer {
    padding: 36px 8px;
    border-top: 1px solid vGray10;
}

.copyright {
    margin: 16px 0 0;
    line-height: 1.25;
    color: vGray1;
}
