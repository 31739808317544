@value vGutterX, vGray1, vViolet4 from "../../styles/variables.css";

.title {
    color: vViolet4;
}

.subTitle {
    font-size: .875rem;
}

.group {
    margin-top: 10px;
}

.input {
    margin-left: 10px;
    width: 60px;
    text-align: center;
}

.info {
    margin-top: 10px;
    min-height: 42px;
}

.recommendedText {
    font-size: .875rem;
    text-align: center;
}

.recommendedPercent {
    font-size: .875rem;
    text-align: center;
    color: vGray1;
}

.controls {

}
