@value vWhite1 from "../../styles/variables.css";

.selectField {
    composes: dynamic from "../../components/Input/Input.module.css";
    position: relative;
}

.selectField_placeholder {
    composes: placeholder from "../../components/Input/Input.module.css";
}

.selectField_placeholderTop {
    top: 1px;
    padding-top: 2px;
    font-size: .75rem;
    transform: translateY(0);
}

.selectField_value {
    padding: 16px 0 0;
    font-size: 1rem;
}
