@value vBlue7 from "../../styles/variables.css";
@value tablet-up, tablet-landscape from "../../styles/breakpoints.css";

.page {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.page_user {
    background: linear-gradient(84deg, #62c5ff, #b84efa);
}

.page_business {

}

.navBar {
    background: transparent;
}

.content {
    display: flex;
    flex: 1 0 auto;
}

.content_user {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 30px 0;
}

.content_business {
    align-items: center;
    justify-content: center;
    padding: 60px 0;
}

.content_form {
    display: flex;
    justify-content: center;
}

.content_preview {
    display: none;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    background-color: vBlue7;
    border-radius: 60px;
    height: calc(100vh - 120px);
}

.preview {
    max-width: calc(100% - 30px);
    max-height: calc(100% - 60px);
}

@media tablet-up {
    .content {

    }

    .content_business {
        padding-right: 30px;
    }

    .content_form {

    }

    .content_preview {
        display: flex;
    }

    .preview {
        max-width: calc(100% - 60px);
        max-height: calc(100% - 60px);
    }
}

@media tablet-landscape {
    .content_business {
        padding-right: 60px;
    }

    .content_form {

    }

    .content_preview {

    }

    .preview {
        max-width: calc(100% - 120px);
        max-height: calc(100% - 120px);
    }
}
