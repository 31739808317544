.avatarPreview {
    margin: 0 auto;
    width: 128px;
    height: auto;
    border-radius: 50%;
    overflow: hidden;
}

.group {
    padding: 0;
    composes: default from "../../components/Input/Input.module.css";
}

.input {
    background-color: inherit;
    font-size: inherit;
    color: inherit;
}

.radio {
    margin-right: 10px;
}

.radio + .radio {
    margin-top: 10px;
}

.selectField {
    padding-left: 0;
    padding-right: 0;
    composes: input;
}

.fieldCountryCode {
    padding-left: 14px;
    padding-right: 4px;
}

.controls {
    composes: controls from "../AuthPhoneForm/AuthPhoneForm.module.css";
}
