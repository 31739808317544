@value vGutter, vGutterX, vGray14, vGray16 from "../../styles/variables.css";
@value tablet-up, big-desktop-up from "../../styles/breakpoints.css";

.content {
    position: relative;
}

.headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.headerContentCount {
    padding-left: 8px;
    font-size: 1.25rem;
    line-height: 1;
    color: vGray14;
}

.headerContentActions {
    display: flex;
}

.headerContentAction {

}

.headerContentAction + .headerContentAction {
    margin-left: 28px;
}

.search {
    margin: 0 calc(vGutterX / 2);
}

.filter {
    border-top: 1px solid vGray16;
    padding: 25px 25px 35px;
}

.blockRequests {
    padding: 25px 20px;
}

.blockRequests_mobile {
    padding: vGutter;
    margin: 0 0 vGutter;
}

.loaderSearch {
    top: 200px;
    z-index: 110;
}

.modalSearch {

}

.modalFiltersOverlay {
    composes: overlay_transparent from "../../styles/modules/overlay.module.css";
}

.iconSearch svg path {
    fill: #000;
}

.iconAdd svg path {
    stroke: #000;
}

@media tablet-up {
    .blockRequests_mobile {
        padding: 25px 20px;
    }
}

@media big-desktop-up {
    .modalSearch {
        max-width: 770px;
    }
}
