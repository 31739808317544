@value vLoadingOpacity, vHoverTransition, vPrimary1, vPrimary3, vGray1, vGreen1, vViolet6, vGray14 from "../../../../styles/variables.css";
@value tablet-landscape-up from "../../../../styles/breakpoints.css";

.link {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

.link_user {

}

.link_business {

}

.link_disabled {
    pointer-events: none;
    opacity: vLoadingOpacity;
}

.link:hover .text,
.active .text {

}

.link_user:hover .text,
.active_user .text {
    color: vPrimary3;
}

.link_business:hover .text,
.active_business .text {
    color: vPrimary1;
}

.link_business:hover .icon svg *,
.active_business .icon svg * {
    fill: vPrimary1;
}

.link_user:hover .icon svg *,
.active_user .icon svg * {
    fill: vPrimary3;
}

.icon {
    position: relative;
    display: block;
}

.icon_user {
    width: 30px;
    height: 30px;
}

.icon_business {
    width: 32px;
    height: 32px;
}

.text {
    display: none;
    word-break: break-word;
    hyphens: auto;
    transition: color vHoverTransition;
}

.text_user {
    padding-left: 30px;
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
}

.text_business {
    padding-left: 40px;
    font-size: 1.125rem;
    font-weight: 500;
    color: vGray14;
}

.count {
    display: none;
    padding-left: 6px;
    font-size: 1.25rem;
    font-weight: 500;
    color: vGray1;
}

.badge {
    display: none;
    margin-left: auto;
}

@media tablet-landscape-up {
    .text,
    .count,
    .badge {
        display: initial;
    }
}
