.pointer {
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, .25);
    cursor: pointer;
    transform: translate(-50%, -50%);
}
