@value vGray14 from "../../../../styles/variables.css";

.menu {
    padding: 12px;
}

.item + .item {
    margin-top: 10px;
}

.link {
    display: flex;
    align-items: center;
    flex-grow: 1;
    text-decoration: none;
}

.link_active .text {
    color: #000;
}

.link_active svg * {
    fill: #000 !important;
}

.text {
    padding-left: 20px;
    font-size: 1.125rem;
    color: vGray14;
}

.iconDropDown svg * {
    fill: vGray14;
}
