@value vGutter, vRadiusSmall, vBlue11, vGray3, vGray6 from "../../../../../styles/variables.css";
@value desktop-up from "../../../../../styles/breakpoints.css";

.header {

}

.headerTitle {

}

.body {

}

.row {

}

.col {
    padding: 10px;
    font-size: .875rem;
    line-height: 1.2;
}

.col_header {
    vertical-align: middle;
}

.col_body {

}

.col_footer {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
}

.colTitle {
    padding: 0 0 5px;
    font-weight: 500;
}

@media desktop-up {
    .row {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .row > .col_body {
        border-top: 1px solid vGray3;
    }

    .col {
        padding: 15px;
        width: auto;
    }

    .colTitle {
        display: none;
    }
}
