@value vRadius from "../../../../styles/variables.css";

.menu {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 0 0 vRadius vRadius;
    overflow: hidden;
}
