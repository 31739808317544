@value vGutterX, vGray14 from "../../styles/variables.css";

.field {

}

.fieldText {
    font-size: .875rem;
}

.fieldValue {
    font-size: 1.25rem;
}

.fieldSelect {
    composes: defaultBusiness from "../../components/Input/Input.module.css";
    font-size: 1rem;
}

.fieldIcon {
    margin: 0 0 0 calc(vGutterX / 2);
}

.modalCalendarBody {
    min-height: 440px;
    padding: 12px 10px 20px;
}

.iconCalendar svg * {
    stroke: vGray14;
}
