@value vGutterX from "../../styles/variables.css";

.pageTitle {
    display: flex;
    align-items: center;
    composes: container from "../../components/PageTitle/PageTitle.module.css";
}

.title {
    padding: 0;
}

.buttonBack {
    margin-left: 4px;
    padding-right: 10px;
}
